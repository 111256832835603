// https://getbootstrap.com/docs/5.1/customize/sass/

$info:          #DE666C;
$primary:       #CF043C;

@import "../node_modules/bootstrap/scss/bootstrap";

// Override bootstrap:
.list-group-item {
  // Give ListGroup component a bit more space
  // default is: 0.5rem 1rem
  padding: 0.65rem 1.3rem;
}

a {
  text-decoration: none;
}

// General classes:

.login {
  margin-top: 50px;
  margin-bottom: 15px;
}

.login-input {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.dashboard {
  margin-left: 0px;
  margin-right: 0px;
}
.projects-list {
  //-webkit-box-shadow: 0px 0px 5px 0px #ccc;
  box-shadow: 10px 0 5px -5px #ccc;
  min-height: calc(100vh - 0px);
}

.project-item {
  margin-left: 15px;
}
.project-search {
  margin-left: 15px;
  margin-bottom: 10px;
  width: auto;
}

.projects-actions {
  margin-left: 15px;
  margin-top: 10px;
}

.phases-list {
   margin: 15px;
}

.phase-folder {
  padding-top: 20px;
  padding-left: 20px;

  .phase-label {
    align-items: center;
    span {
      margin-left: 5px;
    }
  }
}

.phase-item {
  padding-left: 20px;
  align-items: center;
  span {
    margin-left: 5px;
  }
}

.phase-select {
  cursor: pointer;
}

.cadblanche-logo {
  margin-left: 15px;
  margin-top: 40px;
  margin-bottom: 25px;
  width: 100%;
  height: auto;
}

.factory-logo {
  width: auto;
  height: 50px;
  opacity: 90;
}

.error-page {
  margin-top: 100px;
}

.objects-list {
  // background-color: white;
  cursor: move;
}
.objects-list-header {
  // Same color as accordion which is a bit lighter than variant='secondary'
  background-color: rgba(0,0,0,.03);
}
.object-checkbox {
  margin-right: 0.5rem;
}

.exports-list {
  cursor: pointer;
}

.icon-button {
  margin-left: 5px;
}

.icon-button-space {
  margin-left: 5px;
  margin-right: 5px;
}

.dropdown-control {
  margin-left: 2%;
  max-width: 96%;
}

.admin-nav-links {
  margin-bottom: 25px;
}


/* Container styling */
.container-table {
  max-width: fit-content;
}

.container-list {
  margin-top: 30px;
}

.container-list tbody {
  cursor: pointer;
}

.container-item {
  margin-top: 30px;
}

.container-item tbody {
  cursor: pointer;
}

.badge {
  margin-left: 2px;
  margin-right: 2px;
}
.table-click tbody {
  cursor: pointer;
}

.localization-keys-datatable {
  margin-top: 15px;
}

.nav-link-logout {
  margin-left: 7px;
}

.language-dropdown {
  margin-left: 10px;
}